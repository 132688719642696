import 'regenerator-runtime/runtime.js'

import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import App from 'client/app/App/app'

import {
  EnvContextProvider,
  AppContextProvider,
  ModalDialogContextProvider,
} from 'client/hooks'

import './dictionary/i18n'

import 'semantic-ui-css/semantic.min.css'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'
import 'react-toastify/dist/ReactToastify.css'
import 'rsuite/Slider/styles/index.less'
import './styles/index.scss'

let env = {
  AWS_BUCKET_URL: '',
  RECAPTCHA_SITE_KEY: '',
  DONATION_PAYMENT_LINK: '',
  FULL_ACCOUNT_PAYMENT_LINK: '',
}

const envElement = document.getElementById('env')

if (envElement) {
  env = JSON.parse(decodeURIComponent(envElement.innerHTML))
}

ReactDOM[process.env.NODE_ENV === 'production' ? 'hydrate' : 'render'](
  <BrowserRouter>
    <EnvContextProvider
      awsBucketUrl={env.AWS_BUCKET_URL || process.env.AWS_BUCKET_URL}
      recaptchaSiteKey={env.RECAPTCHA_SITE_KEY || process.env.RECAPTCHA_SITE_KEY}
      donationPaymentLink={env.DONATION_PAYMENT_LINK || process.env.DONATION_PAYMENT_LINK}
      fullAccountPaymentLink={
        env.FULL_ACCOUNT_PAYMENT_LINK || process.env.FULL_ACCOUNT_PAYMENT_LINK
      }
    >
      <AppContextProvider>
        <ModalDialogContextProvider>
          <App />
        </ModalDialogContextProvider>
      </AppContextProvider>
    </EnvContextProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
