import { useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

import { AppMsgCode, Filter } from 'atw-shared/utils'
import { RootPage } from 'client/pages'
import { displaySnackBar, getLocalAuthUser, removeLocalAuthUser } from 'client/services'
import { useAppContext, useModalDialogContext } from 'client/hooks'
import { AppUiPreview, AppSnackBar } from 'client/ui-building-blocks'

const App = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { setAuthUser, setSettingsPageState, setBrowseQuestionsPageState } =
    useAppContext()

  const { closeAllModals } = useModalDialogContext()

  const onSettled = (data, err) => {
    const token = getLocalAuthUser?.().token

    if (
      (token && err?.msg?.code === AppMsgCode.AuthenticationFailed) ||
      (!token && err?.msg?.code === AppMsgCode.IllegalAction)
    ) {
      removeLocalAuthUser()
      setSettingsPageState({ tabNo: 1 })
      setBrowseQuestionsPageState(state => ({
        ...state,
        tabKey: Filter.All,
        user: null,
        categories: [],
        search: '',
        pageNo: 1,
      }))
      setAuthUser(null)
      closeAllModals()
      navigate('/auth')
      displaySnackBar(err.msg)
    }
  }

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
          onSettled,
        },
        mutations: {
          retry: false,
          onSettled,
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (process.env.NODE_ENV === 'development' && pathname === '/ui') {
    return <AppUiPreview />
  }

  return (
    <QueryClientProvider client={queryClient}>
      <div id="App">
        <AppSnackBar />
        <RootPage />
      </div>
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default App
